import "./App.css";
import { Routes, Route } from "react-router-dom";
import { Suspense, lazy } from "react";
import Navbar from "./components/Navbar/Navbar";
import IsPrivate from "./components/IsPrivate/IsPrivate";
import IsAnon from "./components/IsAnon/IsAnon";
// Lazy loading pages
const HomePage = lazy(() => import("./pages/HomePage/HomePage"));
const ProfilePage = lazy(() => import("./pages/ProfilePage/ProfilePage"));
const SignupPage = lazy(() => import("./pages/SignupPage/SignupPage"));
const LoginPage = lazy(() => import("./pages/LoginPage/LoginPage"));
const Club = lazy(() => import("./pages/Club/Club"));
const AddPlayer = lazy(() => import("./pages/Player/AddPlayer"));
const ViewPlayer = lazy(() => import("./pages/Player/ViewPlayer"));



function App() {
  return (
    <div className="App">
      <Navbar />
      <div className="pt-20"></div>

      {/* Add Suspense for lazy loading */}
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<HomePage />} />

          <Route
            path="/profile"
            element={
              <IsPrivate>
                <ProfilePage />
              </IsPrivate>
            }
          />

          <Route
            path="/clubs"
            element={
              <IsPrivate>
                <Club />
              </IsPrivate>
            }
          />
          <Route
            path="/view-players"
            element={
              <IsPrivate>
                <ViewPlayer />
              </IsPrivate>
            }
          />

          <Route
            path="/addplayer"
            element={
              <IsPrivate>
                <AddPlayer />
              </IsPrivate>
            }
          />

          <Route
            path="/signup"
            element={
              <IsAnon>
                <SignupPage />
              </IsAnon>
            }
          />
          <Route
            path="/login"
            element={
              <IsAnon>
                <LoginPage />
              </IsAnon>
            }
          />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
