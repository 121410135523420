import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/auth.context";
import { Button } from '../Button/Button';
import { 
  AiOutlineUser, 
  AiOutlineLogout, 
  AiOutlineMenu, 
  AiOutlineClose 
} from 'react-icons/ai';
import { BsFillPersonFill } from 'react-icons/bs';
import { motion, AnimatePresence } from 'framer-motion';

function Navbar() {
  const { isLoggedIn, user, logOutUser } = useContext(AuthContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  // Add scroll effect for navbar
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setMenuOpen(prev => !prev);
  };

  const navVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        type: "spring", 
        stiffness: 120,
        damping: 10
      }
    }
  };

  const mobileMenuVariants = {
    hidden: { opacity: 0, height: 0 },
    visible: { 
      opacity: 1, 
      height: "auto",
      transition: { 
        duration: 0.3,
        ease: "easeInOut"
      }
    }
  };

  return (
    <motion.nav 
      initial="hidden"
      animate="visible"
      variants={navVariants}
      className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 ${
        scrolled 
          ? 'bg-white/90 backdrop-blur-md shadow-lg' 
          : 'bg-white/80 backdrop-blur-sm'
      }`}
    >
      <div className="container mx-auto max-w-6xl flex justify-between items-center px-4 py-4 md:px-6">
        {/* Logo and Title */}
        <Link 
          to="/" 
          className="flex items-center space-x-3 text-2xl font-bold text-gray-800 hover:text-rose-800 transition-colors duration-300 group"
        >
          <motion.svg 
            className="w-10 h-10 text-rose-700 group-hover:rotate-6 transition-transform duration-300" 
            fill="currentColor" 
            viewBox="0 0 24 24"
            whileHover={{ rotate: 15 }}
          >
            <path d="M12 2L15 8h-6l3-6z M3 10h18l-9 10-9-10z" />
          </motion.svg>
          <span className="tracking-tight">Critique Central</span>
        </Link>

        {/* Hamburger Menu for Mobile */}
        <div className="lg:hidden">
          <motion.button 
            onClick={toggleMenu} 
            whileTap={{ scale: 0.9 }}
            className="text-gray-700 hover:text-rose-800 focus:outline-none transition-colors duration-300"
          >
            {menuOpen ? <AiOutlineClose size={28} /> : <AiOutlineMenu size={28} />}
          </motion.button>
        </div>

        {/* Desktop Navigation */}
        <div className="hidden lg:flex items-center space-x-6">
          {isLoggedIn ? (
            <div className="flex items-center space-x-4">
              <Link 
                to="/profile" 
                className="flex items-center text-gray-600 hover:text-rose-800 transition-colors duration-300 group"
              >
                <BsFillPersonFill className="text-xl mr-2 group-hover:text-rose-800 transition-colors" />
                <span className="font-medium">Profile</span>
              </Link>
              <Link 
                to="/addplayer" 
                className="flex items-center text-gray-600 hover:text-rose-800 transition-colors duration-300 group"
              >
                <BsFillPersonFill className="text-xl mr-2 group-hover:text-rose-800 transition-colors" />
                <span className="font-medium">Add Player</span>
              </Link>

              <Link 
                to="/view-players" 
                className="flex items-center text-gray-600 hover:text-rose-800 transition-colors duration-300 group"
              >
                <BsFillPersonFill className="text-xl mr-2 group-hover:text-rose-800 transition-colors" />
                <span className="font-medium">View Players</span>
              </Link>
              <div className="flex items-center space-x-3">
                <motion.span 
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="text-gray-700 font-medium"
                >
                  {user?.name || 'User'}
                </motion.span>
                <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                  <Button
                    onClick={logOutUser}
                    variant="danger"
                    className="flex items-center bg-red-500 text-white hover:bg-red-600 transition-colors duration-300 px-4 py-2 rounded-full"
                  >
                    <AiOutlineLogout className="text-xl mr-2" />
                    Logout
                  </Button>
                </motion.div>
              </div>
            </div>
          ) : (
            <div className="flex items-center space-x-4">
              <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                <Link to="/login">
                  <Button 
                    variant="secondary" 
                    className="bg-gray-500 text-white hover:bg-gray-600 transition-colors duration-300 px-4 py-2 rounded-full"
                  >
                    Login
                  </Button>
                </Link>
              </motion.div>
              <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                <Link to="/signup">
                  <Button 
                    variant="primary" 
                    className="bg-rose-800 text-white hover:bg-rose-900 transition-colors duration-300 px-4 py-2 rounded-full"
                  >
                    Sign Up
                  </Button>
                </Link>
              </motion.div>
            </div>
          )}
        </div>

        {/* Mobile Dropdown Menu */}
        <AnimatePresence>
          {menuOpen && (
            <motion.div 
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={mobileMenuVariants}
              className="absolute top-full left-0 w-full bg-white/95 backdrop-blur-md shadow-lg lg:hidden"
            >
              <div className="container mx-auto px-4 py-6">
                {isLoggedIn ? (
                  <motion.div 
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="space-y-4"
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex items-center space-x-2">
                        <BsFillPersonFill className="text-2xl text-gray-600" />
                        <span className="text-gray-700 font-medium">{user?.name || 'User'}</span>
                      </div>
                      <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                        <Button
                          onClick={logOutUser}
                          variant="danger"
                          className="flex items-center bg-red-500 text-white hover:bg-red-600 transition-colors duration-300 px-4 py-2 rounded-full"
                        >
                          <AiOutlineLogout className="text-xl mr-2" />
                          Logout
                        </Button>
                      </motion.div>
                    </div>
                    <hr className="border-gray-200" />
                    <Link 
                      to="/profile" 
                      className="block text-gray-600 hover:text-rose-800 transition-colors duration-300 py-2"
                    >
                      View Profile
                    </Link>
                    <Link 
                      to="/addplayer" 
                      className="block text-gray-600 hover:text-rose-800 transition-colors duration-300 py-2"
                    >
                      Add Player
                    </Link>
                    <Link 
                      to="/view-players" 
                      className="block text-gray-600 hover:text-rose-800 transition-colors duration-300 py-2"
                    >
                      View Players
                    </Link>
                  </motion.div>
                ) : (
                  <motion.div 
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="space-y-4"
                  >
                    <Link to="/login" className="block w-full">
                      <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                        <Button 
                          variant="secondary" 
                          className="w-full bg-gray-500 text-white hover:bg-gray-600 transition-colors duration-300 px-4 py-3 rounded-full"
                        >
                          Login
                        </Button>
                      </motion.div>
                    </Link>
                    <Link to="/signup" className="block w-full">
                      <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                        <Button 
                          variant="primary" 
                          className="w-full bg-rose-800 text-white hover:bg-rose-900 transition-colors duration-300 px-4 py-3 rounded-full"
                        >
                          Sign Up
                        </Button>
                      </motion.div>
                    </Link>
                  </motion.div>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.nav>
  );
}

export default Navbar;