import React from 'react';

export const Button = ({ children, variant = 'primary', className, ...props }) => {
  const baseStyles = 'py-2 px-4 rounded-md focus:outline-none focus:ring focus:ring-opacity-50 transition duration-200 transform hover:scale-105 hover:shadow-lg';
  
  const variantStyles = {
    primary: 'bg-[#9f1239] text-white hover:bg-[#b86c05]', // Orange primary button
    secondary: 'bg-[#9f1239] text-white hover:bg-[#b86c05]', // Dark orange secondary button
    danger: 'bg-red-500 text-white hover:bg-red-600', // Red danger button
    link: 'text-[#9f1239] hover:underline', // Link style
  };

  return (
    <button
      className={`${baseStyles} ${variantStyles[variant]} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};
